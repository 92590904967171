import request from '@/utils/request'


export function login(data) {
    return request({
        url: '/api/login',
        method: 'post',
        params: data
    })
}

///api/get_user_info
export function getUserInfo(data) {
    return request({
        url: '/api/get_user_info',
        method: 'get',
        params: data
    })
}

///api/sx
export function sx(data) {
    return request({
        url: '/api/sx',
        method: 'get',
        params: data
    })
}

///api/qiuzhangdie
export function qiuzhangdie(data) {
    return request({
        url: '/api/qiuzhangdie',
        method: 'post',
        params: data
    })
}

///api/cemingzi
export function cemingzi(data) {
    return request({
        url: '/api/cemingzi',
        method: 'post',
        data:data
    })
}

///api/register
export function register(data) {
    return request({
        url: '/api/register',
        method: 'post',
        data: data
    })
}

//api/ceshanzhai
export function ceshanzhai(data) {
    return request({
        url: '/api/ceshanzhai',
        method: 'post',
        data: data
    })
}

//api/save_share
export function saveShare(data) {
    return request({
        url: '/api/save_share',
        method: 'post',
        data: data
    })
}

///api/get_share
export function getShare(data) {
    return request({
        url: '/api/get_share',
        method: 'post',
        params: data
    })
}