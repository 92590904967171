<template>
  <div class="appmain">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>
<script>
import { jinanUserLogin } from "@/api/all";
import { getToken, setToken } from '@/utils/auth';
import { getUserInfoSafe } from '@/utils/Jsbridge';
export default {
  data() {
    return {
      pathUrl: '',
      isIos: false
    }
  },
  watch: {
    $route(to, from) {
      this.pathUrl = to.fullPath;

    }
  },
  created() {

  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Alimama ShuHeiTi Bold';
  src: url('~@/assets/font/AlimamaShuHeiTi-Bold.ttf') format('truetype');
  /* 可以根据字体文件的类型修改 format() 的参数，比如woff2、otf等 */
}

* {
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE/Edge */
  user-select: none;

  /* Generic (also works in IE11) */
  &::-webkit-scrollbar {
    display: none;
  }
}

p {
  font-size: 26px;
}

div {
  font-size: 26px;
}

.mtop {
  padding-top: 40px !important;
}

.contDetail {
  width: 100%;

  p {
    color: rgba(21, 43, 52, 1);
    font-size: 28px;
    font-weight: 400;
    font-family: "PingFang SC";
    text-align: left;
    line-height: 48px;
    letter-spacing: 1.16px;
  }

  img {
    width: 100% !important;
    display: block;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

/* 渐变设置 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active {
  transition: all 0.7s ease;
}

.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.6, 0.6, 1);
}

/* 通用清除 */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Chrome, Safari, Edge */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  border-radius: 0 !important;
  background-color: transparent !important;
  background-image: none !important;
  color: inherit;
  font: inherit;
}

/* Firefox */
input[type="password"] {
  background-color: transparent !important;
  background-image: none !important;
  color: inherit;
  font: inherit;
}

/* IE10+ */
input:-ms-autocomplete {
  background-color: transparent !important;
  background-image: none !important;
  color: inherit;
  font: inherit;
}

/* IE11 */
input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}
</style>
