import axios from 'axios'
import router from '@/router'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { showNotify } from 'vant';
// create an axios instance
// 'http://49.234.211.89:7500' 测试
const service = axios.create({
    baseURL: 'https://www.bitmancy.com', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 15000 // request timeout
})
// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['Authorization'] = getToken()
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.status !== 'success') {
            let token = getToken();
            if (!token) {
                router.push({
                    path: '/login',
                    replace: true
                })
                return false
            } else if (res.err_msg == '未登录') {
                router.push({
                    path: '/login',
                    replace: true
                })
                return false
            }
        }
        return res

    },
    error => {
        // showNotify({ type: 'warning', message: error.response.err_msg });
        // router.push({
        //     path: '/login',
        //     replace: true
        // })
        return Promise.reject(error)
    }
)

export default service