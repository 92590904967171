import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

const routes = [{
    path: "/",
    name: "index",
    component: () =>
        import ("../views/pan.vue"),
},{
    path: "/login",
    name: "login",
    component: () =>
        import ("../views/login.vue"),
},{
    path: "/detail",
    name: "detail",
    component: () =>
        import ("../views/detail.vue"),
},{
    path: "/fragrant",
    name: "fragrant",
    component: () =>
        import ("../views/fragrant.vue"),
},{
    path: "/search",
    name: "search",
    component: () =>
        import ("../views/search.vue"),
},{
    path: "/sign",
    name: "sign",
    component: () =>
        import ("../views/sign.vue"),
}];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

export default router;